export const range = (length: number, start = 0) =>
  Array.from({ length }, (_, i) => i + start);

export const slugify = (text: string) =>
  text
    .replace(/ T T/g, " TT")
    .toLowerCase()
    .replace(/[\s?!.,&]+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
