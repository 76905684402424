import { useContext, useEffect, useState } from "react";
import { LangContext } from "./context";
import { LangPrefix } from "./enums";

// purePath: lang prefix를 제외한 pathname, index.html이면 "/"
// pathname: window.location.pathname
export const useLocation = (): { purePath: string; pathname: string } => {
  const { setLangPrefix } = useContext(LangContext);
  const [pathname, setPathname] = useState("");
  const [purePath, setPurePath] = useState("");

  useEffect(() => {
    const newPath = window.location.pathname;
    if (newPath !== pathname) {
      setPathname(newPath);
      if (newPath.startsWith("/ko")) {
        setLangPrefix(LangPrefix.Ko);
        setPurePath(newPath.slice(3) || "/");
      } else if (newPath.startsWith("/zh-tw")) {
        setLangPrefix(LangPrefix.Cht);
        setPurePath(newPath.slice(6) || "/");
      } else {
        setLangPrefix(LangPrefix.En);
        setPurePath(newPath || "/");
      }
    }
  });

  return { purePath, pathname };
};
