import React from "react";

export type NavbarContextType = {
  color: boolean;
  text: boolean;
};

export const NavbarContext = React.createContext<NavbarContextType>({
  color: true,
  text: false,
});
export const NavbarContextProvider = NavbarContext.Provider;

export const LangContext = React.createContext({
  langPrefix: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLangPrefix: (v: string) => {},
});
export const LangContextProvider = LangContext.Provider;

export const LoadingContext = React.createContext({
  loading: true,
  setLoading: () => {},
});
export const LoadingContextProvider = LoadingContext.Provider;
