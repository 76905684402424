import {
  AlbumKey,
  BlackSemiBold,
  ChatItemType,
  LangPrefix,
  Npc,
  StoryType,
  TextColor,
} from "../enums";

export const npcToKo = {
  [Npc.Hi]: "하이",
  [Npc.Leo]: "리오",
  [Npc.Sol]: "솔",
  [Npc.Sujee]: "수지",
  [Npc.Wonseok]: "원석",
  [Npc.Aiden]: "에이든",
  [Npc.Ted]: "테드",
  [Npc.Claude]: "클라우드",
  [Npc.Seojoon]: "서준",
  [Npc.James]: "제임스 로저스",
  [Npc.Peipei]: "페이페이",
  [Npc.Siu]: "시우",
  [Npc.Babe]: "베이브",
  [Npc.William]: "윌리엄",
  [Npc.Shallote]: "샤샤",
  [Npc.Pierce]: "피어스",
  [Npc.Zenon]: "제논",
  [Npc.Dorian]: "도리안 그레이",
  [Npc.Eater]: "이터",
  [Npc.YoungWilliam]: "어린 윌리엄",
  [Npc.Chaser]: "체이서",
  [Npc.Agni]: "아그니",
};

export const storyTypeToKo = {
  [StoryType.Chat]: "채팅방",
  [StoryType.VN]: "비쥬얼노벨",
  [StoryType.CE]: "황당엔딩",
  [StoryType.Over]: "게임 오버",
  [StoryType.HE]: "해피엔딩",
  [StoryType.NE]: "노멀엔딩",
  [StoryType.DE]: "다크엔딩",
  [StoryType.CEWarning]: "황당엔딩을 대비하여 여기서 저장",
};

export const storyTypeToCht = {
  [StoryType.Chat]: "聊天室",
  [StoryType.VN]: "視覺小說",
  [StoryType.CE]: "荒誕結局（Comical Ending）",
  [StoryType.Over]: "Game Over",
  [StoryType.HE]: "幸福結局（Happy Ending）",
  [StoryType.NE]: "一般結局（Normal Ending）",
  [StoryType.DE]: "黑暗結局（Dark Ending）",
  [StoryType.CEWarning]: "在此處保存進度以確保迴避CE",
};

export const StorySummaryDescriptions = {
  HiCall: { text: "Hi's call", color: TextColor.Hi },
  LeoCall: { text: "Leo's call", color: TextColor.Leo },
  SolCall: { text: "Sol's call", color: TextColor.Sol },
  EaterCall: { text: "Eater's call", color: TextColor.Eater },
  WilliamCall: { text: "William's call", color: TextColor.William },
  GOWarning: {
    text: "Be careful of Game Over.",
    color: BlackSemiBold,
  },
  DE: { text: "Condition: Miss many chatrooms." },
};

export const StorySummaryDescriptionsKo = {
  HiCall: { text: "하이 통화", color: TextColor.Hi },
  LeoCall: { text: "리오 통화", color: TextColor.Leo },
  SolCall: { text: "솔 통화", color: TextColor.Sol },
  EaterCall: { text: "이터 통화", color: TextColor.Eater },
  WilliamCall: { text: "윌리엄 통화", color: TextColor.William },
  GOWarning: {
    text: "게임 오버에 유의",
    color: BlackSemiBold,
  },
  DE: { text: "조건: 부재중인 스토리 많음" },
};

export const StorySummaryDescriptionsCht = {
  HiCall: { text: "Hi來電", color: TextColor.Hi },
  LeoCall: { text: "Leo來電", color: TextColor.Leo },
  SolCall: { text: "Sol來電", color: TextColor.Sol },
  EaterCall: { text: "Eater來電", color: TextColor.Eater },
  WilliamCall: { text: "William來電", color: TextColor.William },
  GOWarning: {
    text: "小心遊戲結束（Game Over）",
    color: BlackSemiBold,
  },
  DE: { text: "達成條件：錯過大量聊天室" },
};

const AlbumGenerator = (albumKey: AlbumKey) => (key: string) => {
  return { text: albumKey + "$" + key, type: ChatItemType.Photo };
};

const CandidateGenerator =
  (color: string, colorblind: string, underline: string | null = null) =>
  (text: string) => {
    return { text, color, colorblind, underline };
  };

export const ChatItems = {
  Hi: CandidateGenerator(TextColor.Hi, "Hi"),
  Leo: CandidateGenerator(TextColor.Leo, "Leo"),
  Sol: CandidateGenerator(TextColor.Sol, "Sol"),
  Eater: CandidateGenerator(
    TextColor.Eater,
    "Eater",
    "decoration-beige-eater-bright"
  ),
  William: CandidateGenerator(
    TextColor.William,
    "William",
    "decoration-red-william-bright"
  ),
  Shallote: CandidateGenerator(TextColor.Shallote, "Shallote"),
  HiAlbum: AlbumGenerator(AlbumKey.Hi),
  LeoAlbum: AlbumGenerator(AlbumKey.Leo),
  SolAlbum: AlbumGenerator(AlbumKey.Sol),
  WilliamAlbum: AlbumGenerator(AlbumKey.William),
  EaterAlbum: AlbumGenerator(AlbumKey.Eater),
  OtherAlbum: AlbumGenerator(AlbumKey.Other),
  GameOver: () => ({
    text: "Game Over",
    color: BlackSemiBold,
    type: ChatItemType.Description,
  }),
  Babe: CandidateGenerator(TextColor.Babe, "*"),
};

export const ChatItemsKo = {
  Hi: CandidateGenerator(TextColor.Hi, "하이"),
  Leo: CandidateGenerator(TextColor.Leo, "리오"),
  Sol: CandidateGenerator(TextColor.Sol, "솔"),
  Eater: CandidateGenerator(
    TextColor.Eater,
    "이터",
    "decoration-beige-eater-bright"
  ),
  William: CandidateGenerator(
    TextColor.William,
    "윌리엄",
    "decoration-red-william-bright"
  ),
  Shallote: CandidateGenerator(TextColor.Shallote, "샤샤"),
  HiAlbum: AlbumGenerator(AlbumKey.Hi),
  LeoAlbum: AlbumGenerator(AlbumKey.Leo),
  SolAlbum: AlbumGenerator(AlbumKey.Sol),
  EaterAlbum: AlbumGenerator(AlbumKey.Eater),
  WilliamAlbum: AlbumGenerator(AlbumKey.William),
  OtherAlbum: AlbumGenerator(AlbumKey.Other),
  GameOver: () => ({
    text: "게임 오버",
    color: BlackSemiBold,
    type: ChatItemType.Description,
  }),
  Babe: CandidateGenerator(TextColor.Babe, "*"),
};

export const ChatItemsCht = {
  ...ChatItems,
  GameOver: () => ({
    text: "遊戲結束",
    color: BlackSemiBold,
    type: ChatItemType.Description,
  }),
};

export const TitleInfoGenerator =
  (route: string, lang: LangPrefix = LangPrefix.En) =>
  (day: number) => {
    return {
      shortTitle:
        lang === LangPrefix.Ko ? `${route} ${day}일차` : `${route} Day${day}`,
      route: `day${day}`,
    };
  };
