import React, { HTMLAttributes, FC, useState, useEffect } from "react";
import ResponsiveNavBar from "./ResponsiveNavBar";
import { ScrollToElement } from "./ScrollToElement";
import {
  LangContextProvider,
  LoadingContextProvider,
  NavbarContextProvider,
  NavbarContextType,
} from "../context";
import { useLocation } from "../hooks";

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {}

const Layout: FC<LayoutProps> = ({ children }) => {
  const [context, setContext] = useState({ color: true, text: false });
  const [langPrefix, setLangPrefix] = useState("");
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);

  const setContextAndStorage = (v: NavbarContextType) => {
    setContext(v);
    localStorage.setItem("color", `${v.color}`);
    localStorage.setItem("text", `${v.text}`);
  };

  useEffect(() => {
    const colorblind = localStorage.getItem("colorblind");
    if (colorblind !== null) {
      localStorage.removeItem("colorblind");
      setContextAndStorage({ color: true, text: colorblind === "true" });
    } else {
      const color = localStorage.getItem("color");
      const text = localStorage.getItem("text");
      if (color !== null)
        setContext({ color: color === "true", text: text === "true" });
      else setContextAndStorage({ color: true, text: false });
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [pathname]);

  return (
    <NavbarContextProvider value={context}>
      <LangContextProvider value={{ langPrefix, setLangPrefix }}>
        <LoadingContextProvider
          value={{ loading, setLoading: () => setLoading(true) }}
        >
          <ScrollToElement />
          <ResponsiveNavBar
            onSettingsChange={setContextAndStorage}
            setLoading={() => setLoading(true)}
          />
          <main className="md:pl-56 w-full h-full relative break-keep">
            {children}
          </main>
        </LoadingContextProvider>
      </LangContextProvider>
    </NavbarContextProvider>
  );
};

export default Layout;
