import React, { FC, HTMLAttributes } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { range } from "../utils";
import { TextColor } from "../enums";
import { NavBarCategory, NavBarItem } from "../types";
import AlbumIcon from "../assets/icons/album-icon.svg";

interface IconProps extends HTMLAttributes<HTMLImageElement> {}

const AlbumIconWithStyle: FC<IconProps> = ({ className = "" }) => (
  <img src={AlbumIcon} className={`p-0.5 ${className}`} alt="album-icon" />
);

const FavoriteBorderIconWithStyle: FC<IconProps> = ({ className = "" }) => (
  <FavoriteBorderIcon className={className} />
);

const TimestampItem: NavBarItem = {
  en: "Timestamp",
  ko: "시간표",
  cht: "時間表",
  route: "timestamp",
};

const DayItemGenerator = (length: number, start: number) =>
  range(length, start).map((i) => ({
    en: `Day ${i}`,
    ko: `${i}일차`,
    cht: `Day ${i}`,
    route: `day${i}`,
  }));

export const data: NavBarCategory[] = [
  {
    en: "Common Route",
    ko: "공통 루트",
    cht: "共通路線",
    route: "common",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Common,
    list: [TimestampItem, ...DayItemGenerator(7, 1)],
  },
  {
    en: "Hi's Route",
    ko: "하이 루트",
    cht: "Hi路線",
    route: "hi",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Hi,
    list: [TimestampItem, ...DayItemGenerator(7, 8)],
  },
  {
    en: "Leo's Route",
    ko: "리오 루트",
    cht: "Leo路線",
    route: "leo",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Leo,
    list: [TimestampItem, ...DayItemGenerator(7, 8)],
  },
  {
    en: "Sol's Route",
    ko: "솔 루트",
    cht: "Sol路線",
    route: "sol",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Sol,
    list: [TimestampItem, ...DayItemGenerator(7, 8)],
  },
  {
    en: "Sol's If Route",
    ko: "솔 if 루트",
    cht: "Sol if路線",
    route: "sol-if",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.SolIf,
    list: [TimestampItem, ...DayItemGenerator(7, 8)],
  },
  {
    en: "Eater's Route",
    ko: "이터 루트",
    cht: "Eater路線",
    route: "eater",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Eater,
    list: [TimestampItem, ...DayItemGenerator(9, 9)],
  },
  {
    en: "William's Route",
    ko: "윌리엄 루트",
    cht: "William路線",
    route: "william",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.William,
    list: [TimestampItem, ...DayItemGenerator(9, 9)],
  },
  {
    en: "DLC",
    ko: "DLC",
    cht: "DLC",
    route: "dlc",
    icon: FavoriteBorderIconWithStyle,
    iconColor: TextColor.Common,
    list: [
      {
        en: "Shallote's Route",
        ko: "샤샤 루트",
        cht: "Shallote路線",
        route: "shallote",
      },
      {
        en: "Season 3 Prologue",
        ko: "시즌 3 프롤로그",
        cht: "第3季序幕",
        route: "s3-prologue",
      },
      {
        en: "We never forget you",
        ko: "We never forget you",
        cht: "We never forget you",
        route: "never-forget",
      },
    ],
  },
  {
    en: "Albums",
    ko: "앨범",
    cht: "相簿",
    route: "album",
    icon: AlbumIconWithStyle,
    iconColor: TextColor.Common,
    list: [
      { en: "Hi's Album", ko: "하이 앨범", cht: "Hi的相簿", route: "hi" },
      { en: "Leo's Album", ko: "리오 앨범", cht: "Leo的相簿", route: "leo" },
      { en: "Sol's Album", ko: "솔 앨범", cht: "Sol的相簿", route: "sol" },
      {
        en: "William's Album",
        ko: "윌리엄 앨범",
        cht: "William的相簿",
        route: "william",
      },
      {
        en: "Eater's Album",
        ko: "이터 앨범",
        cht: "Eater的相簿",
        route: "eater",
      },
      {
        en: "Other Photos",
        ko: "기타 앨범",
        cht: "其他照片資料",
        route: "other",
      },
    ],
  },
];
