module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LoveUnholyc Walkthrough","short_name":"LU Walkthrough","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","icon":"src/assets/icons/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1924a2262e1b60109bae88aa97d568c1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/loveunholyc-walkthrough/loveunholyc-walkthrough/src/components/Layout.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
