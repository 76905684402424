import React, { FC, useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavBar } from "./NavBar";
import { data } from "../data/NavBarData";
import { NavbarContextType } from "../context";

export interface ResponsiveNavBarProps {
  onSettingsChange: (v: NavbarContextType) => void;
  setLoading: () => void;
}

const ResponsiveNavBar: FC<ResponsiveNavBarProps> = ({
  onSettingsChange,
  setLoading,
}) => {
  const [openNav, setOpenNav] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(
    new Array(data.length).fill(false)
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const handler = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (window.screen.width >= 768) setOpenNav(false);
        timeout = null;
      }, 100);
    };
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);

  const toggle = (idx: number) =>
    setOpenAccordion((value) => {
      const newValue = value.slice();
      newValue[idx] = !value[idx];
      return newValue;
    });

  return (
    <>
      <div className="md:hidden">
        <button
          className="fixed z-50 top-3 left-3 w-10 h-10 border border-gray-common rounded-xl shadow-md bg-white opacity-80"
          onClick={() => setOpenNav(true)}
        >
          <MenuIcon className="text-red-hi" sx={{ fontSize: 27 }} />
        </button>
        <Drawer
          variant="temporary"
          open={openNav}
          onClose={() => setOpenNav(false)}
        >
          <NavBar
            handleClick={() => {
              setOpenNav(false);
            }}
            open={openAccordion}
            toggle={toggle}
            onSettingsChange={onSettingsChange}
            setLoading={setLoading}
          />
        </Drawer>
      </div>
      <Drawer variant="permanent" className="hidden md:block">
        <NavBar
          open={openAccordion}
          toggle={toggle}
          onSettingsChange={onSettingsChange}
          setLoading={setLoading}
        />
      </Drawer>
    </>
  );
};

export default ResponsiveNavBar;
