import { LangPrefix, StoryType } from "./enums";
import { storyTypeToCht, storyTypeToKo } from "./data/DataTemplate";
import { Album, NavBarCategory, NavBarItem, Photo } from "./types";

export const storyTypeToLang = (langPrefix: string, storyType: StoryType) =>
  langPrefix === LangPrefix.Ko
    ? storyTypeToKo[storyType]
    : langPrefix === LangPrefix.Cht
    ? storyTypeToCht[storyType]
    : storyType;

export const whereOfPhoto = (langPrefix: string, photo: Photo) =>
  langPrefix === LangPrefix.Ko
    ? photo.whereKo
    : langPrefix === LangPrefix.Cht
    ? photo.whereCht
    : photo.where;

export const textOfPhoto = (langPrefix: string, photo: Photo) =>
  langPrefix === LangPrefix.Ko
    ? photo.textKo
    : langPrefix === LangPrefix.Cht && photo.textCht
    ? photo.textCht
    : photo.text;

export const nameOfCategory = (langPrefix: string, category: NavBarCategory) =>
  langPrefix === LangPrefix.Ko
    ? category.ko
    : langPrefix === LangPrefix.Cht
    ? category.cht
    : category.en;

export const nameOfNavBarItem = (langPrefix: string, item: NavBarItem) =>
  langPrefix === LangPrefix.Ko
    ? item.ko
    : langPrefix === LangPrefix.Cht
    ? item.cht
    : item.en;

export const explanationOfAlbum = (langPrefix: string, album: Album) =>
  langPrefix === LangPrefix.Ko
    ? album.explanationKo
    : langPrefix === LangPrefix.Cht
    ? album.explanationCht
    : album.explanation;

export const titleOfAlbum = (langPrefix: string, album: Album) =>
  langPrefix === LangPrefix.Ko
    ? album.titleKo
    : langPrefix === LangPrefix.Cht
    ? album.titleCht
    : album.title;
