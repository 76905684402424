exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ko-index-tsx": () => import("./../../../src/pages/ko/index.tsx" /* webpackChunkName: "component---src-pages-ko-index-tsx" */),
  "component---src-templates-album-template-tsx": () => import("./../../../src/templates/AlbumTemplate.tsx" /* webpackChunkName: "component---src-templates-album-template-tsx" */),
  "component---src-templates-chinese-index-tsx": () => import("./../../../src/templates/ChineseIndex.tsx" /* webpackChunkName: "component---src-templates-chinese-index-tsx" */),
  "component---src-templates-dlc-template-tsx": () => import("./../../../src/templates/DLCTemplate.tsx" /* webpackChunkName: "component---src-templates-dlc-template-tsx" */),
  "component---src-templates-route-template-tsx": () => import("./../../../src/templates/RouteTemplate.tsx" /* webpackChunkName: "component---src-templates-route-template-tsx" */),
  "component---src-templates-timestamp-template-tsx": () => import("./../../../src/templates/TimestampTemplate.tsx" /* webpackChunkName: "component---src-templates-timestamp-template-tsx" */)
}

