// tailwind.config.js에서 정의한 색상들
export enum TextColor {
  Common = "text-gray-common",
  Hi = "text-red-hi",
  Leo = "text-yellow-leo",
  Sol = "text-blue-sol",
  SolIf = "text-blue-sol-if",
  William = "text-red-william",
  Eater = "text-beige-eater",
  Shallote = "text-red-shallote",
  Black = "text-black-custom",
  Babe = "text-cyan-babe",
}

export const BlackSemiBold = `${TextColor.Black} font-semibold`;

export enum LangPrefix {
  En = "",
  Ko = "/ko",
  Cht = "/zh-tw",
}

// Album, DLC, Route와 같은 data object의 필드
// Background.tsx의 BackgroundFromKey에서 특정 background로 변환
export enum BackgroundKey {
  Common = "Common",
  Hi = "Hi",
  Leo = "Leo",
  Sol = "Sol",
  SolIf = "SolIf",
  William = "William",
  Eater = "Eater",
  Shallote = "Shallote",
  EaterPrologue = "EaterPrologue",
  NeverForget = "NeverForget",
}

// Background component에서 prop으로 받음
export enum HorizontalDirection {
  Left = "left",
  Right = "right",
  Center = "center",
}

// Background component에서 prop으로 받음
export enum VerticalDirection {
  Top = "top",
  Center = "center",
  Bottom = "bottom",
}

// Story object의 필드
// StoryComponent, TimestampCard 등의 component에서 layout을 선택하는 기준
export enum StoryType {
  Chat = "Chatroom",
  VN = "Visual Novel",
  CE = "Comical Ending",
  Over = "Game Over",
  HE = "Happy Ending",
  NE = "Normal Ending",
  DE = "Dark Ending",
  CEWarning = "Save here to make sure you pass the CE",
}

// ChatItem의 필드
// 각 선택지, 일러 또는 설명의 타입을 정의
// 대부분의 경우 DataTemplate.ts의 AlbumGenerator, ChatItems.GameOver에서 값을 넣어줌
export enum ChatItemType {
  Candidate = "Candidate",
  Description = "Description",
  Photo = "Photo",
}

// Album의 필드
// DataTemplate.ts의 AlbumGenerator에서 text를 만들 때 사용
// createPages.ts에서 이를 바탕으로 데이터를 파싱함
export enum AlbumKey {
  Hi = "Hi",
  Leo = "Leo",
  Sol = "Sol",
  Eater = "Eater",
  William = "William",
  Other = "Other",
}

// Day, Route, Album과 같은 data object의 필드
// 실제 내용은 Explanation component에서 정의
export enum ExplanationKey {
  NoKo = "NoKo",
  NoCht = "NoCht",
  S3Prologue = "S3Prologue",
  NeverForget = "NeverForget",
  InitializeMidnight = "InitializeMidnight",
  Progress = "Progress",
  WilliamCE = "WilliamCE",
}

// Story의 필드
// 각 스토리 공략에서 적히는 설명의 key
// 실제 내용은 StoryDescription component에서 정의
export enum StoryDescriptionKey {
  None = "None",
  Progress = "Progress",
  DE = "DarkEnding",
  EaterDE = "EaterDarkEnding",
  WilliamDE = "WilliamDarkEnding",
  HiHE = "HiHappyEnding",
  LeoHE = "LeoHappyEnding",
  SolHE = "SolHappyEnding",
  EaterHE = "EaterHappyEnding",
  WilliamHE = "WilliamHappyEnding",
  Sujee = "Sujee",
  CatBusWarning = "CatBusWarning",
  CatBus = "CatBus",
  MetaWarning = "MetaWarning",
  Meta = "Meta",
  Rabbit = "WhiteRabbit",
  WizardWarning = "WizardWarning",
  Wizard = "Wizard",
  MagicalWarning = "MagicalWarning",
  Magical = "Magical",
  SphinxWarning = "SphinxWarning",
  Sphinx = "Sphinx",
  JamesWedding = "JamesWedding",
  JamesRogersWarning = "JamesRogersWarning",
  JamesRogers = "JamesRogers",
  SaiyanWarning = "SaiyanWarning",
  Saiyan = "Saiyan",
  Story = "Story",
  Siu = "Siu",
  Puppy = "Puppy",
  YourName = "YourName",
  Ponny = "Ponny",
  Sleaper = "Sleaper",
  PocketmonWarning = "PocketmonWarning",
  Pocketmon = "Pocketmon",
  AdventureTime = "AdventureTime",
  ShalloteHE = "ShalloteHappyEnding",
  ShalloteDE = "ShalloteDarkEnding",
}

// 서브스토리에서 얻는 일러에 대한 정보를 기록하기 위해 사용
// AlbumData.ts의 substory 함수에서 사용
// 한글 이름 매핑은 DataTemplate.ts에서 정의
export enum Npc {
  Hi = "Hi",
  Leo = "Leo",
  Sol = "Sol",
  Sujee = "Sujee",
  Wonseok = "Wonseok",
  Aiden = "Aiden",
  Ted = "Ted",
  Claude = "Claude",
  Seojoon = "Seo Joon",
  James = "James Rogers",
  Peipei = "PeiPei",
  Siu = "Siu",
  Babe = "Babe",
  William = "William",
  Shallote = "Shallote",
  Pierce = "Pierce",
  Zenon = "Zenon",
  Dorian = "Dorian Gray",
  Eater = "Eater",
  YoungWilliam = "Young William",
  Chaser = "Chaser",
  Agni = "Agni",
}
