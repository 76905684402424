import React, { FC, HTMLAttributes, useContext } from "react";
import { LangContext } from "../context";
import { LangPrefix } from "../enums";

export interface TextProps extends HTMLAttributes<HTMLHeadElement> {}

const Title: FC<TextProps> = ({ children, className, style }) => {
  const { langPrefix } = useContext(LangContext);
  return (
    <p
      className={`${
        langPrefix === LangPrefix.Ko
          ? "font-binggrae"
          : "font-noto-serif italic"
      } ${
        langPrefix === LangPrefix.Cht ? "break-normal" : "break-words"
      } ${className}`}
      style={style}
    >
      {children}
    </p>
  );
};

const Heading: FC<TextProps> = ({ children, className, style }) => {
  const { langPrefix } = useContext(LangContext);
  return (
    <p
      className={`${
        langPrefix === LangPrefix.Ko ? "font-daehan" : "font-roboto-slab"
      } ${
        langPrefix === LangPrefix.Cht ? "break-normal" : "break-words"
      } ${className}`}
      style={style}
    >
      {children}
    </p>
  );
};

const Body: FC<TextProps> = ({ children, className, style }) => {
  const { langPrefix } = useContext(LangContext);
  return (
    <p
      className={`${
        langPrefix === LangPrefix.Ko
          ? "font-pretendard"
          : "font-source-sans-pro"
      } ${
        langPrefix === LangPrefix.Cht ? "break-normal" : "break-words"
      } ${className}`}
      style={style}
    >
      {children}
    </p>
  );
};

const Text = { Title, Heading, Body };
export default Text;
