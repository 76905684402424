import { useEffect, useState } from "react";
import { useLocation } from "../hooks";

export const ScrollToElement = (): null => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [scroller, setScroller] = useState<any>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const load = async () => {
      const SmoothScroll = await import("smooth-scroll");
      if (!active) return;
      setScroller(
        new SmoothScroll.default('a[href*="#"]', {
          speedAsDuration: true,
        })
      );
    };

    let active = true;
    load();
    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    if (scroller) {
      const hash = window.location.hash;
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) scroller.animateScroll(element);
        else window.scrollTo(0, 0);
      } else window.scrollTo(0, 0);
    }
  }, [scroller, pathname]);
  return null;
};
