import React, { FC, HTMLAttributes, useContext } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Text from "../components/Text";
import { styled } from "@mui/material/styles";
import { data } from "../data/NavBarData";
import { Link, navigate } from "gatsby";
import { LangContext, NavbarContext, NavbarContextType } from "../context";
import MuiDivider from "@mui/material/Divider";
import { useLocation } from "../hooks";
import { LangPrefix } from "../enums";
import { nameOfCategory, nameOfNavBarItem } from "../langUtils";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    variant="outlined"
    {...props}
  />
))(() => ({
  borderLeft: "0",
  borderRight: "0",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": { height: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: 14 }}
        className="text-black-custom"
      />
    }
    {...props}
  />
))(() => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

export interface NavBarProps extends HTMLAttributes<HTMLDivElement> {
  handleClick?: () => void;
  open: boolean[];
  toggle: (idx: number) => void;
  onSettingsChange: (v: NavbarContextType) => void;
  setLoading: () => void;
}

export const NavBar: FC<NavBarProps> = ({
  handleClick,
  open,
  toggle,
  onSettingsChange,
  setLoading,
}: NavBarProps) => {
  const { pathname, purePath } = useLocation();
  const { langPrefix } = useContext(LangContext);
  const { color, text } = useContext(NavbarContext);

  const textData =
    langPrefix === LangPrefix.Ko
      ? {
          color: "색깔로 선택지 표시",
          text: "텍스트로 선택지 표시",
          lastUpdate: "최근 수정일",
          copyright: "사용된 모든 이미지의 저작권은 프리티비지에 있습니다",
        }
      : langPrefix === LangPrefix.Cht
      ? {
          color: "使用顏色標記選項",
          text: "使用文本標記選項",
          lastUpdate: "Last Update",
          copyright: "所有使用圖像均屬於PrettyBusy",
        }
      : {
          color: "Use color to mark choices",
          text: "Use text to mark choices",
          lastUpdate: "Last Update",
          copyright: "All the images used belong to PrettyBusy",
        };

  const onTitleClick = () => {
    if (purePath !== "/") setLoading();
    handleClick?.();
  };

  return (
    <div className="w-56 h-full flex flex-col scrollbar-hidden overflow-y-auto overflow-x-auto overscroll-y-none">
      {langPrefix === LangPrefix.Ko ? (
        <Link
          className="flex px-3 pt-3 items-end justify-center pb-2"
          to="/ko/"
          onClick={onTitleClick}
        >
          <Text.Title className="text-3xl text-red-hi w-38">
            러브언홀릭
          </Text.Title>
          <Text.Title className="text-base text-gray-common">공략</Text.Title>
        </Link>
      ) : (
        <Link
          className="flex flex-col px-3 pt-3 pb-2 items-end"
          to={`${langPrefix}/`}
          onClick={onTitleClick}
        >
          <Text.Title className="text-3xl font-bold text-red-hi">
            LoveUnholyc
          </Text.Title>
          <Text.Title className="text-xs font-bold text-gray-common">
            Walkthrough made by Yujin
          </Text.Title>
        </Link>
      )}

      <div>
        {data.map((category, idx) => (
          <Accordion
            key={category.route}
            expanded={open[idx]}
            onChange={() => toggle(idx)}
          >
            <AccordionSummary>
              <div className="flex space-x-2 items-center">
                <category.icon className={`w-6 ${category.iconColor}`} />
                <Text.Heading
                  className={`text-black-custom ${
                    langPrefix === LangPrefix.Ko
                      ? "text-xl mt-0.5 -mb-0.5"
                      : "text-lg"
                  }`}
                >
                  {nameOfCategory(langPrefix, category)}
                </Text.Heading>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, paddingLeft: 6 }}>
              <div className="flex flex-col mb-2 pr-10 -ml-2">
                {category.list.map((item) => {
                  const route = `${langPrefix}/${category.route}/${item.route}`;
                  return (
                    <Link
                      key={item.route}
                      className={`pl-2 py-1 bg-opacity-10 rounded-md ${
                        pathname.startsWith(route) && `font-bold bg-gray-common`
                      }`}
                      to={route}
                      onClick={() => {
                        if (!pathname.startsWith(route)) setLoading();
                        handleClick?.();
                      }}
                    >
                      <Text.Heading className="text-gray-common text-left text-sm">
                        {nameOfNavBarItem(langPrefix, item)}
                      </Text.Heading>
                    </Link>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <div className="flex items-center px-4 space-x-2 mt-2">
        <input
          type="checkbox"
          checked={color}
          onChange={(e) => onSettingsChange({ color: e.target.checked, text })}
        />
        <Text.Body className="text-black-custom text-sm">
          {textData.color}
        </Text.Body>
      </div>
      <div className="flex items-center px-4 space-x-2 mb-2">
        <input
          type="checkbox"
          checked={text}
          onChange={(e) => onSettingsChange({ color, text: e.target.checked })}
        />
        <Text.Body className="text-black-custom text-sm">
          {textData.text}
        </Text.Body>
        <div className="flex-grow" />
      </div>

      <div className="h-8 flex-shrink-0">
        <select
          value={langPrefix}
          onChange={(e) => {
            setLoading();
            navigate(`${e.target.value}${purePath}`);
            handleClick?.();
          }}
          className="text-sm mx-3 w-20 border rounded mb-3 bg-white"
        >
          <option value={LangPrefix.En}>English</option>
          <option value={LangPrefix.Ko}>한국어</option>
          <option value={LangPrefix.Cht}>繁體中文</option>
        </select>
      </div>

      <MuiDivider />

      <Text.Body className="text-gray-common text-xs px-4 pt-1 mb-4">
        {textData.lastUpdate}: 2024-06-25
        <br />
        {textData.copyright}
      </Text.Body>
      <div className="flex-grow" />
    </div>
  );
};
